.CreateButton {
  align-self: center;
  width: 9rem;
}

.CreateButton .ui.basic.button {
  box-shadow: none;
  padding: 0;
  margin-right: 1rem;
  margin-left: 1rem;
}

.CreateButtonModal .PatientSearchBox > .ui.fluid.search {
  width: 400px;
}

.CreateButtonModal .PatientSearchBox > .ui.fluid.search.focus {
  border: 1px solid #85b7d9;
  border-radius: 0.25rem;
}

.CreateButtonModal > .content {
  padding-top: 0rem !important;
}

.CreateButtonModal > .content > #create-task > .input {
  width: 400px;
}

.CreateButtonModal .input-label {
  margin-top: 1rem;
  color: #7F8FA4;
  font-weight: 700;
  font-size: 0.95rem;
  margin-bottom: 0.3rem;
}
