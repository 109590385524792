.ParentModal.ui.modal > .content {
  min-height: 12rem;
  overflow: initial;
}

.ParentModal.ui.modal > .content.scrolling {
  overflow: auto;
}

.ParentModal.ui.modal {
  padding-bottom: 1rem;
  background: #f9fafb;
}

.ParentModal.ui.modal > .image > .content {
  flex: 1;
}

.ParentModal.ui.modal > .content > .description {
  min-height: 5rem;
}

.ParentModal.ui.modal > .content > .description > .row > .column > .ui > .ui.input {
  width: 65%;
  margin-bottom: 1em;
}

.ParentModal.ui.modal > div.results.transition.visible {
  margin-top: 0em;
}
