.ProviderList > .row {
  margin: 0 1em;
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
  border-bottom: 1px solid rgba(34,36,38,.15);
}

.ProviderList > .row:last-of-type {
  border-bottom: 0px solid rgba(34,36,38,.15);
}

.ProviderList .image-cropper {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.ProviderList > .row, .column > .header {
  margin-bottom: 0em;
}

.ProviderList .image-cropper img {
  border-radius: 15%;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.ProviderList > .row > .column:nth-of-type(2) > .specialty {
  margin-bottom: 1em;
}

.ProviderList > .row > .column:nth-of-type(2) > .office-info {
  margin-top: 1em;
}

.ProviderList .emphasized {
  font-weight: 900;
}

.ProviderList .column:last-of-type > div:last-of-type {
  margin-top: 1em;
  color: rgba(0, 0, 0, 0.5);
}

.ProviderList .positive {
  color:  #1E988B;
}

.procedures-header {
  margin-top: 1em;
}

.ProviderList .section-header {
  font-size: 0.688em;
}
