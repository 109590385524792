.ContactIcon .icon {
  font-size: 1em !important;
  line-height: 1em !important;
}

.ProviderList > .row {
  margin: 0 1em;
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
  border-bottom: 1px solid rgba(34,36,38,.15);
}

.ProviderList > .row:last-of-type {
  border-bottom: 0px solid rgba(34,36,38,.15);
}

.ProviderList .image-cropper {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.ProviderList > .row, .column > .header {
  margin-bottom: 0em;
}

.ProviderList .image-cropper img {
  border-radius: 15%;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.ProviderList > .row > .column:nth-of-type(2) > .specialty {
  margin-bottom: 1em;
}

.ProviderList > .row > .column:nth-of-type(2) > .office-info {
  margin-top: 1em;
}

.ProviderList .emphasized {
  font-weight: 900;
}

.ProviderList .column:last-of-type > div:last-of-type {
  margin-top: 1em;
  color: rgba(0, 0, 0, 0.5);
}

.ProviderList .positive {
  color:  #1E988B;
}

.procedures-header {
  margin-top: 1em;
}

.ProviderList .section-header {
  font-size: 0.688em;
}

.SearchResults a * {
  color: #2185d0;
}

.SearchResults .warning {
  color: #f2711c;
}

.SearchResults .label {
  margin-bottom: 0.3em;
}

.SearchResults .relaxed {
  margin-top: .5em;
  line-height: 1.5em;
}

.SearchResults .relaxed > span {
  margin-right: 0.75em;
}

.SearchResults h2.notice {
  margin-top: 7em !important;
}

.SearchResults .segment.basic {
  padding: 0;
  color: rgb(119, 119, 119);
}

.SearchResults .row.condensed {
  padding-bottom: 0;
}

.SearchResults > .row > .column > .list > .item > .content > .ui.grid > .column.contact-icon {
  font-size: 1.3em;
  width: 3.5rem;
}

.SearchResults .inline-logo {
  height: 1em;
  margin-left: .4em;
}

.SearchResults .result {
  padding-top: 1em;
  padding-bottom: 1em;
}

.SearchResults .result.inactive {
  opacity: .5;
}

.SearchResults .result .notice {
  font-style: italic;
}

.SearchResults .result.content .header {
  line-height: 1.1em;
}

.SearchResults .result.content .header > div > i {
  margin-left: 0.5em;
}

.SearchResults .insurances  > div > span {
  padding-top: 0.25em;
  line-height: 1.5em;
}

.SearchResults .truncate {
  display: inline-block;
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SearchBox {
  padding: 1em 10em 2em 10em;
}

.SearchBox:not(.condensed) {
  margin-top: 12em;
}

.SearchBox .search {
  flex: 1 1;
}

.SearchBox .search .input input {
  padding-right: 0.5em !important;
  padding-left: 0.5em !important;
  border-right: 0;
}

.SearchBox .search input:focus {
  border-color: rgba(34, 36, 38, 0.15);
  border-left: none;
}

.SearchBox .search .input .icon {
  display: none;
}

.SearchBox .search .prompt {
  border-radius: 0;
}

.SearchBox .input .label {
  border-radius: 0;
  border: 1px solid rgba(34,36,38,.15);
  border-right: 0;
  padding-right: 0;
  padding-top: .9em !important;
  background: #fff;
  color: rgba(0,0,0,.87);
  box-shadow: none;
}

.SearchBox .search:first-child .input .label {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.SearchBox .result {
   font-weight: 900;
   font-size: .9em !important;
   border: none !important;
   padding-top: 0.4em !important;
   padding-bottom: 0.4em !important;
}

.SearchBox .result > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SearchBox .result > div > em {
  font-weight: 100;
  font-style: normal;
}

.ContactsPage .fab {
  position: fixed;
  bottom: 2em;
  right: 2em;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2);
}

.ContactsPage .fab:hover {
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}

.ContactsPage .fab .button {
  margin: 0;
}

.ContactsPage .fab .menu {
  margin-bottom: .5em!important;
}

.ContactsPage .fab .menu .item:first-child {
  border-bottom: 1px solid rgba(34,36,38,.15);
}

.Section {
  margin-top: 2em;
  margin-bottom: 2em;
}

.Section > .header {
  margin-bottom: 1em;
}

.BasicInformationSection .ui.grid .field .row.inheritance-label {
  color: #7F8FA4;
  font-size: .93em;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.BasicInformationSection .row .ui.basic.button {
  cursor: default;
  background: white !important;
}

.BasicInformationSection .row .ui.basic.button:hover {
  color: rgba(0,0,0,.6)!important;
  box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset !important;
}

.InheritanceDiffTable > tbody > tr > td > .crossed-out {
  text-decoration: line-through;
}

.InheritanceDiffTable > tbody > tr > td > div:nth-child(3) {
  display: inline;
}

.InheritanceDiffTable > tbody > tr > td > span > span {
  color:#2185d0;
  cursor: pointer;
}

.InheritanceDiffTable > tbody > tr > td > span:nth-child(4) {
  color:#2185d0;
  cursor: pointer;
}

.InheritanceDiffTable > tbody > tr > td > p {
  margin: 0 0 0;
}

.ParentModal.ui.modal > .content {
  min-height: 12rem;
  overflow: visible;
  overflow: initial;
}

.ParentModal.ui.modal > .content.scrolling {
  overflow: auto;
}

.ParentModal.ui.modal {
  padding-bottom: 1rem;
  background: #f9fafb;
}

.ParentModal.ui.modal > .image > .content {
  flex: 1 1;
}

.ParentModal.ui.modal > .content > .description {
  min-height: 5rem;
}

.ParentModal.ui.modal > .content > .description > .row > .column > .ui > .ui.input {
  width: 65%;
  margin-bottom: 1em;
}

.ParentModal.ui.modal > div.results.transition.visible {
  margin-top: 0em;
}

.IconButton {
  background: 0 !important;
}

.InheritanceLockIcon {
  opacity: 0.4 !important;
  color: black;
  transition: 0.5s;
}

.InheritanceLockIcon:hover{
  opacity: 1 !important;
}

.ContactInformationSection .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled, .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
  opacity: 1.0;
}

.ContactInformationSection > .Section .no-physical-location-checkbox {
  display: flex !important;
}

.ContactInformationSection > .Section .no-physical-location-checkbox {
  color: #7F8FA4;
  font-size: 0.93em;
  font-weight: 700;
}

.ContactInformationSection > .Section .no-physical-location-checkbox > a {
  margin-top: 0.12em;
  margin-left: 0.25em;
}

.EditParentWarningModal.ui.modal {
  padding-bottom: 1rem;
  background: #f9fafb;
}

.EditParentWarningModal > .content div {
  line-height: 1.75rem;
}

.EditParentWarningModal > .content .segment {
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.EditParentWarningModal > .content .segment div:last-of-type {
  margin-left: 0.5rem;
}

/* a hack to fix the alignment of checkboxes used in header rows */
.InsurancesSection .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before,
.InsurancesSection .ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after {
  top: .1em
}

.InsurancesSection .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  pointer-events: all
}

.ui.form .field > label > .insurance-section-label {
  display: flex;
  justify-content: space-between;
}

.field > label > .insurance-section-label > div > a > .InheritanceLockIcon {
  margin-right: 1.1em;
  font-size: 1.1em;
}
.NotesSection > .Section >.ui.grid > .row > .partner-checkbox {
  display: flex;
}

.NotesSection .partner-checkbox > .ui.label {
  background-color: transparent;
  color:#7F8FA4;
  font-size: 0.95em;
}

.NotesSection > .Section >.ui.grid > .row > .partner-checkbox > a {
  margin-top: 0.25em;
}

.NotesSection .partner-checkbox .box:before, .ui.toggle.checkbox label:before {
  background-color: rgba(0,0,0,.15);
}

.NotesSection .partner-checkbox .box:before, .ui.toggle.checkbox label:after {
  border: 1px solid rgba(0,0,0,.15);
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-label {
  color: #7F8FA4;
  font-weight: 700;
  font-size: 0.95em;
  margin-bottom: 0.3em;
  display: flex;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-label > .partnership-section-title {
  margin-right: 0.5em;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-checkbox-label {
  display: flex;
  text-align: center;
  margin-bottom: -0.8em;
  line-height: 1em;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-checkbox-label > .partner-benefits-checkbox-icon {
  margin: 0 0.4em 0 0.4em;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-checkbox-label > .partner-benefits-checkbox-icon.fa-award {
  margin: 0 0.6em 0 0.6em;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-checkbox-label > .field > .partner-benefits-checkbox {
  margin-right: 0.3em;
}

.ContactForm {
  margin-top: 2em;
  margin-bottom: 2em;
}

.ContactForm .field label, .ContactForm .accordion .title {
  color: #7F8FA4 !important;
}

.ContactForm .inline.fields {
  padding-top: .7em;
  padding-bottom: .6em;
  margin-bottom: 0 !important;
}

.ContactForm .ui.loading.form:after {
  top: 15em;
}

.ContactForm .hidden {
  display: none;
}

.ContactForm .selectable:hover {
  background-color: rgba(202, 203, 205, .5);
  cursor: pointer;
}

.ContactEditPage {
  padding-top: 1em;
}

.ContactEditPage .timestamp {
  color: rgb(119, 119, 119);
}

.ContactEditPage .verbatim {
  white-space: pre-line;
  font-style: italic;
}

.ContactEditPage .input > a > .InheritanceLockIcon {
  margin-left: -2.35em;
  margin-top: 0.6em;
}

.TasksNavigation > .menu {
  width: 100% !important;
}

.TasksNavigation > .menu > .item > .svg-inline--fa {
  margin-right: 0.5em;
}

.TasksNavigation > hr {
  border-color: rgba(34,36,38,.15);
  border-width: 0.5px;
  margin-left: -1rem;
  margin-right: -1rem;
}

.TasksNavigation > .menu > div > .item {
  margin-left: 0rem !important;
}

.TasksNavigation .hide {
  display: none;
}

.InternalUserSearch > .input {
  width: 100%;
}

.InternalUserSearch > .input > .prompt {
  border-radius: 0.25rem;
}

.ScoreBoard > span {
  font-weight: 700;
  padding-right: 0.5em;
}

.ScoreBoard .accordion, .title {
  display: inline !important;
}

.ScoreBoard .ui.table td {
  border: 0;
  font-weight: 400;
  font-size: 0.93em;
  padding: .3em 0 .3em 0;
}

.ScoreBoard .ui.table td:nth-of-type(2) {
  text-align: right;
}

.TaskQueue > span {
  font-weight: 700;
}

.TaskQueue .ui.table {
  margin-top: .5em;
}

.TaskQueue .ui.table td {
  border: 0;
  font-weight: 400;
  font-size: 0.93em;
  padding: .3em 0 .3em 0;
}

.TaskQueue .ui.table td:nth-of-type(2) {
  text-align: right;
}

.StateButton {
  margin-top: 0.4em;
}

.StateButton .button, .StateButton .button:focus {
  color: #fff;
  background-color: #2185d0;
}

.StateButton .button:hover {
  color: #fff;
  background-color: #1678c2;
}

.StateButton .button:nth-child(2) {
  margin-left: 1px;
}

.StateButton .dropdown {
  padding-left: .5em;
  padding-right: .5em;
}

.StateButton .dropdown .icon {
  margin: 0 !important;
}

.modal-datepicker .react-datepicker__day-name,
.modal-datepicker .react-datepicker__day,
.modal-datepicker .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
.modal-datepicker .react-datepicker__current-month,
.modal-datepicker .react-datepicker-time__header {
  font-size: 1.44rem;
}
.modal-datepicker .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}
.modal-datepicker .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}
.modal-datepicker .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}
.modal-datepicker .react-datepicker__navigation--previous--disabled,
.modal-datepicker .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}
.modal-datepicker .react-datepicker__navigation--next {
  border-left-color: #ccc;
}
.modal-datepicker .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}
.modal-datepicker .react-datepicker__navigation--next--disabled,
.modal-datepicker .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.PriorityButton {
  cursor: pointer;
  opacity: 0.70;
}

.PriorityButton > .image > .ui.mini.label {
  cursor: pointer;
  margin-top: -3.9em;
}

.PriorityButton > .image > .ui.corner.label .icon {
  cursor: pointer;
}

.ActivityLabel {
  margin-bottom: 0.25em;
  color: rgba(0,0,0,.5);
  font-size: .9em;
}

.ActivityLabel .label.circular {
  margin-left: -1.15rem;
  margin-right: 0.5rem;
}


.EmptyTasksList {
  text-align: center;
  color: rgb(128, 128, 128);
  /* padding-bottom: 1rem; */
  padding-top: 2.5rem !important;
}

.EmptyTasksList > .header {
  color: rgb(128, 128, 128);
  padding-top: 1rem;
}

.EmptyTasksList > div {
  margin-bottom: 3rem;
}

.TasksList .task {
  padding-right: 0em;
}

.TasksList .task .ui.grid .row:first-of-type {
  padding-bottom: 0.5em;
}

.TasksList .task .row:last-of-type {
  padding-top: 0em;
}

.TasksList .task h4 {
  margin-bottom: .25em !important;
  margin-top: 0em;
}

.TasksList .task h4 svg {
  margin-left: .5em;
}

.TasksList .task .ui.grid .row .middle-column {
  margin-top: -0.3em;
}

.TasksList .task .truncated {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TasksList .task .actions .row:nth-child(1) {
  padding-bottom: 0;
}

.TasksList .task .actions .row:nth-child(2) {
  padding-top: 0;
}

.TasksList .task .actions .column:nth-child(1) {
  padding-right: 0;
}

.TasksList .task .actions .column:nth-child(2) {
  padding-left: 0;
  padding-right: 0;
}

.TasksList .task.secondary .actions .column:nth-child(2),
.TasksList .task.tertiary .actions .column:nth-child(2) {
  opacity: .5;
}

.TasksList .task .sla-timestamp {
  color: rgba(0,0,0,.5);
  font-size: .9em;
}

.TasksList .task .ShowHide.ui.accordion .title {
  color: #4183c4; /* standard Semantic blue */
  padding-top: 0;
  padding-bottom: 0;
}

.TasksList .task .ShowHide.ui.accordion .title > i {
  margin-left: -0.4em;
}

.TasksList .search {
  display: inline-block;
}

.TasksList .search .input {
  margin-top: -.5em;
  margin-right: 2em;
}

.TasksList .search .input input {
  margin-left: 0 !important;
}

.TasksList .label > .icon {
  margin: 0
}

.TasksList .segment > .loader {
  margin: 1rem 0;
}

.TasksList > .grid {
  margin-top: 1rem;
}

.TasksList .pagination-container {
  text-align: center;
  align-self: inherit;
}

.hover-container:not(:hover) .hoverable:not(.visible) {
  display: none;
}

.TasksPage {
  margin: 1rem !important;
}

.PatientSearchBox .ui.search .prompt {
  border-radius: 4px;
  margin-top: 2px;
}

.PatientSearchBox .ui.fluid.search {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.25rem;
}

.PatientSearchBox .ui.fluid.search.small {
  width: 150px;
}

.PatientSearchBox .ui.fluid.search.expanded {
  width: 400px;
}

.PatientSearchBox .ui.fluid.search>.results .result .description {
  font-size: 0.857em;
  color: rgba(0,0,0,.6);
}

.PatientSearchBox .highlighted {
  background-color: #FEF4E4;
}

.CreateButton {
  align-self: center;
  width: 9rem;
}

.CreateButton .ui.basic.button {
  box-shadow: none;
  padding: 0;
  margin-right: 1rem;
  margin-left: 1rem;
}

.CreateButtonModal .PatientSearchBox > .ui.fluid.search {
  width: 400px;
}

.CreateButtonModal .PatientSearchBox > .ui.fluid.search.focus {
  border: 1px solid #85b7d9;
  border-radius: 0.25rem;
}

.CreateButtonModal > .content {
  padding-top: 0rem !important;
}

.CreateButtonModal > .content > #create-task > .input {
  width: 400px;
}

.CreateButtonModal .input-label {
  margin-top: 1rem;
  color: #7F8FA4;
  font-weight: 700;
  font-size: 0.95rem;
  margin-bottom: 0.3rem;
}

.TasksListHeader > .ui.attached.segment {
  border-bottom: 0rem;
  vertical-align: middle;
}

.TasksListHeader > .segment > .personal-task-header {
  margin-bottom: 0rem;
}

.TasksListHeader > .segment > .header:nth-of-type(1) {
  padding-top: 0.6rem;
}
.TasksListHeader > .segment > .header {
  margin-left: 1rem;
  margin-bottom: 0rem;
}

.TasksListHeader > .segment > .header.right.floated {
  margin-right: 0rem;
}

.TasksListHeader > .segment > .header > .personal-task-rank-dropdown {
  height: 2rem;
}

.TasksListHeader > .segment > .header > .menu > .personal-task-rank-label {
  align-self: center;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.TasksListHeader > .segment > .header > .personal-task-rank-dropdown > div[role=listbox] > .menu {
  width: 12.5rem;
}

.App {
  min-width: 1420px;
}

.App .ui.container {
  min-width: 1155px;
}

.App :disabled {
  cursor: not-allowed;
}

.Logo {
  fill: white;
  height: 1.2em;
}
  
.NavBar div[role="listbox"] > .svg-inline--fa {
  margin-left: .5em;
  color: #fbdd08;
}

.NavBar div[role="option"] > .svg-inline--fa {
  margin-right: .5em;
}

.NavBar.ui.inverted.menu {
  background-color: #434343;
  border-radius: 0;
}

.NavBar.ui.inverted.menu a.item, .NavBar.ui.inverted.menu .item {
  color: #ffffff;
  margin-top: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.NavBar.menu .item:first-child {
  padding: .7em;
}

.NavBar.ui.menu .item:before {
  width: 0px;
}

body {
  overflow: scroll;

  background-color: #F6F6F6;
}

/* Used by modals */
.dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.modal {
  margin: 0 auto !important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease .5s;
}

