.EmptyTasksList {
  text-align: center;
  color: rgb(128, 128, 128);
  /* padding-bottom: 1rem; */
  padding-top: 2.5rem !important;
}

.EmptyTasksList > .header {
  color: rgb(128, 128, 128);
  padding-top: 1rem;
}

.EmptyTasksList > div {
  margin-bottom: 3rem;
}
