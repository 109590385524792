.EditParentWarningModal.ui.modal {
  padding-bottom: 1rem;
  background: #f9fafb;
}

.EditParentWarningModal > .content div {
  line-height: 1.75rem;
}

.EditParentWarningModal > .content .segment {
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.EditParentWarningModal > .content .segment div:last-of-type {
  margin-left: 0.5rem;
}
