/* a hack to fix the alignment of checkboxes used in header rows */
.InsurancesSection .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before,
.InsurancesSection .ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after {
  top: .1em
}

.InsurancesSection .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  pointer-events: all
}

.ui.form .field > label > .insurance-section-label {
  display: flex;
  justify-content: space-between;
}

.field > label > .insurance-section-label > div > a > .InheritanceLockIcon {
  margin-right: 1.1em;
  font-size: 1.1em;
}