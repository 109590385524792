.PriorityButton {
  cursor: pointer;
  opacity: 0.70;
}

.PriorityButton > .image > .ui.mini.label {
  cursor: pointer;
  margin-top: -3.9em;
}

.PriorityButton > .image > .ui.corner.label .icon {
  cursor: pointer;
}
