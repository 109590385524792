.ContactsPage .fab {
  position: fixed;
  bottom: 2em;
  right: 2em;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2);
}

.ContactsPage .fab:hover {
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}

.ContactsPage .fab .button {
  margin: 0;
}

.ContactsPage .fab .menu {
  margin-bottom: .5em!important;
}

.ContactsPage .fab .menu .item:first-child {
  border-bottom: 1px solid rgba(34,36,38,.15);
}
