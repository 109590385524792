.NavBar div[role="listbox"] > .svg-inline--fa {
  margin-left: .5em;
  color: #fbdd08;
}

.NavBar div[role="option"] > .svg-inline--fa {
  margin-right: .5em;
}

.NavBar.ui.inverted.menu {
  background-color: #434343;
  border-radius: 0;
}

.NavBar.ui.inverted.menu a.item, .NavBar.ui.inverted.menu .item {
  color: #ffffff;
  margin-top: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.NavBar.menu .item:first-child {
  padding: .7em;
}

.NavBar.ui.menu .item:before {
  width: 0px;
}
