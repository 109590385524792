.SearchBox {
  padding: 1em 10em 2em 10em;
}

.SearchBox:not(.condensed) {
  margin-top: 12em;
}

.SearchBox .search {
  flex: 1;
}

.SearchBox .search .input input {
  padding-right: 0.5em !important;
  padding-left: 0.5em !important;
  border-right: 0;
}

.SearchBox .search input:focus {
  border-color: rgba(34, 36, 38, 0.15);
  border-left: none;
}

.SearchBox .search .input .icon {
  display: none;
}

.SearchBox .search .prompt {
  border-radius: 0;
}

.SearchBox .input .label {
  border-radius: 0;
  border: 1px solid rgba(34,36,38,.15);
  border-right: 0;
  padding-right: 0;
  padding-top: .9em !important;
  background: #fff;
  color: rgba(0,0,0,.87);
  box-shadow: none;
}

.SearchBox .search:first-child .input .label {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.SearchBox .result {
   font-weight: 900;
   font-size: .9em !important;
   border: none !important;
   padding-top: 0.4em !important;
   padding-bottom: 0.4em !important;
}

.SearchBox .result > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SearchBox .result > div > em {
  font-weight: 100;
  font-style: normal;
}
