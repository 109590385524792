.TasksListHeader > .ui.attached.segment {
  border-bottom: 0rem;
  vertical-align: middle;
}

.TasksListHeader > .segment > .personal-task-header {
  margin-bottom: 0rem;
}

.TasksListHeader > .segment > .header:nth-of-type(1) {
  padding-top: 0.6rem;
}
.TasksListHeader > .segment > .header {
  margin-left: 1rem;
  margin-bottom: 0rem;
}

.TasksListHeader > .segment > .header.right.floated {
  margin-right: 0rem;
}

.TasksListHeader > .segment > .header > .personal-task-rank-dropdown {
  height: 2rem;
}

.TasksListHeader > .segment > .header > .menu > .personal-task-rank-label {
  align-self: center;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.TasksListHeader > .segment > .header > .personal-task-rank-dropdown > div[role=listbox] > .menu {
  width: 12.5rem;
}
