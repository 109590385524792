.TasksList .task {
  padding-right: 0em;
}

.TasksList .task .ui.grid .row:first-of-type {
  padding-bottom: 0.5em;
}

.TasksList .task .row:last-of-type {
  padding-top: 0em;
}

.TasksList .task h4 {
  margin-bottom: .25em !important;
  margin-top: 0em;
}

.TasksList .task h4 svg {
  margin-left: .5em;
}

.TasksList .task .ui.grid .row .middle-column {
  margin-top: -0.3em;
}

.TasksList .task .truncated {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TasksList .task .actions .row:nth-child(1) {
  padding-bottom: 0;
}

.TasksList .task .actions .row:nth-child(2) {
  padding-top: 0;
}

.TasksList .task .actions .column:nth-child(1) {
  padding-right: 0;
}

.TasksList .task .actions .column:nth-child(2) {
  padding-left: 0;
  padding-right: 0;
}

.TasksList .task.secondary .actions .column:nth-child(2),
.TasksList .task.tertiary .actions .column:nth-child(2) {
  opacity: .5;
}

.TasksList .task .sla-timestamp {
  color: rgba(0,0,0,.5);
  font-size: .9em;
}

.TasksList .task .ShowHide.ui.accordion .title {
  color: #4183c4; /* standard Semantic blue */
  padding-top: 0;
  padding-bottom: 0;
}

.TasksList .task .ShowHide.ui.accordion .title > i {
  margin-left: -0.4em;
}

.TasksList .search {
  display: inline-block;
}

.TasksList .search .input {
  margin-top: -.5em;
  margin-right: 2em;
}

.TasksList .search .input input {
  margin-left: 0 !important;
}

.TasksList .label > .icon {
  margin: 0
}

.TasksList .segment > .loader {
  margin: 1rem 0;
}

.TasksList > .grid {
  margin-top: 1rem;
}

.TasksList .pagination-container {
  text-align: center;
  align-self: inherit;
}
