.ContactForm {
  margin-top: 2em;
  margin-bottom: 2em;
}

.ContactForm .field label, .ContactForm .accordion .title {
  color: #7F8FA4 !important;
}

.ContactForm .inline.fields {
  padding-top: .7em;
  padding-bottom: .6em;
  margin-bottom: 0 !important;
}

.ContactForm .ui.loading.form:after {
  top: 15em;
}

.ContactForm .hidden {
  display: none;
}

.ContactForm .selectable:hover {
  background-color: rgba(202, 203, 205, .5);
  cursor: pointer;
}
