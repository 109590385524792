.ActivityLabel {
  margin-bottom: 0.25em;
  color: rgba(0,0,0,.5);
  font-size: .9em;
}

.ActivityLabel .label.circular {
  margin-left: -1.15rem;
  margin-right: 0.5rem;
}

