.InheritanceDiffTable > tbody > tr > td > .crossed-out {
  text-decoration: line-through;
}

.InheritanceDiffTable > tbody > tr > td > div:nth-child(3) {
  display: inline;
}

.InheritanceDiffTable > tbody > tr > td > span > span {
  color:#2185d0;
  cursor: pointer;
}

.InheritanceDiffTable > tbody > tr > td > span:nth-child(4) {
  color:#2185d0;
  cursor: pointer;
}

.InheritanceDiffTable > tbody > tr > td > p {
  margin: 0 0 0;
}
