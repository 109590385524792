.ContactEditPage {
  padding-top: 1em;
}

.ContactEditPage .timestamp {
  color: rgb(119, 119, 119);
}

.ContactEditPage .verbatim {
  white-space: pre-line;
  font-style: italic;
}

.ContactEditPage .input > a > .InheritanceLockIcon {
  margin-left: -2.35em;
  margin-top: 0.6em;
}
