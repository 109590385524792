.SearchResults a * {
  color: #2185d0;
}

.SearchResults .warning {
  color: #f2711c;
}

.SearchResults .label {
  margin-bottom: 0.3em;
}

.SearchResults .relaxed {
  margin-top: .5em;
  line-height: 1.5em;
}

.SearchResults .relaxed > span {
  margin-right: 0.75em;
}

.SearchResults h2.notice {
  margin-top: 7em !important;
}

.SearchResults .segment.basic {
  padding: 0;
  color: rgb(119, 119, 119);
}

.SearchResults .row.condensed {
  padding-bottom: 0;
}

.SearchResults > .row > .column > .list > .item > .content > .ui.grid > .column.contact-icon {
  font-size: 1.3em;
  width: 3.5rem;
}

.SearchResults .inline-logo {
  height: 1em;
  margin-left: .4em;
}

.SearchResults .result {
  padding-top: 1em;
  padding-bottom: 1em;
}

.SearchResults .result.inactive {
  opacity: .5;
}

.SearchResults .result .notice {
  font-style: italic;
}

.SearchResults .result.content .header {
  line-height: 1.1em;
}

.SearchResults .result.content .header > div > i {
  margin-left: 0.5em;
}

.SearchResults .insurances  > div > span {
  padding-top: 0.25em;
  line-height: 1.5em;
}

.SearchResults .truncate {
  display: inline-block;
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
