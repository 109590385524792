.App {
  min-width: 1420px;
}

.App .ui.container {
  min-width: 1155px;
}

.App :disabled {
  cursor: not-allowed;
}
