.StateButton {
  margin-top: 0.4em;
}

.StateButton .button, .StateButton .button:focus {
  color: #fff;
  background-color: #2185d0;
}

.StateButton .button:hover {
  color: #fff;
  background-color: #1678c2;
}

.StateButton .button:nth-child(2) {
  margin-left: 1px;
}

.StateButton .dropdown {
  padding-left: .5em;
  padding-right: .5em;
}

.StateButton .dropdown .icon {
  margin: 0 !important;
}

.modal-datepicker .react-datepicker__day-name,
.modal-datepicker .react-datepicker__day,
.modal-datepicker .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
.modal-datepicker .react-datepicker__current-month,
.modal-datepicker .react-datepicker-time__header {
  font-size: 1.44rem;
}
.modal-datepicker .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}
.modal-datepicker .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}
.modal-datepicker .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}
.modal-datepicker .react-datepicker__navigation--previous--disabled,
.modal-datepicker .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}
.modal-datepicker .react-datepicker__navigation--next {
  border-left-color: #ccc;
}
.modal-datepicker .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}
.modal-datepicker .react-datepicker__navigation--next--disabled,
.modal-datepicker .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}
