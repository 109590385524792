body {
  overflow: scroll;

  background-color: #F6F6F6;
}

/* Used by modals */
.dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.modal {
  margin: 0 auto !important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease .5s;
}
