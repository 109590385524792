.PatientSearchBox .ui.search .prompt {
  border-radius: 4px;
  margin-top: 2px;
}

.PatientSearchBox .ui.fluid.search {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.25rem;
}

.PatientSearchBox .ui.fluid.search.small {
  width: 150px;
}

.PatientSearchBox .ui.fluid.search.expanded {
  width: 400px;
}

.PatientSearchBox .ui.fluid.search>.results .result .description {
  font-size: 0.857em;
  color: rgba(0,0,0,.6);
}

.PatientSearchBox .highlighted {
  background-color: #FEF4E4;
}
