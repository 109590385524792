.NotesSection > .Section >.ui.grid > .row > .partner-checkbox {
  display: flex;
}

.NotesSection .partner-checkbox > .ui.label {
  background-color: transparent;
  color:#7F8FA4;
  font-size: 0.95em;
}

.NotesSection > .Section >.ui.grid > .row > .partner-checkbox > a {
  margin-top: 0.25em;
}

.NotesSection .partner-checkbox .box:before, .ui.toggle.checkbox label:before {
  background-color: rgba(0,0,0,.15);
}

.NotesSection .partner-checkbox .box:before, .ui.toggle.checkbox label:after {
  border: 1px solid rgba(0,0,0,.15);
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-label {
  color: #7F8FA4;
  font-weight: 700;
  font-size: 0.95em;
  margin-bottom: 0.3em;
  display: flex;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-label > .partnership-section-title {
  margin-right: 0.5em;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-checkbox-label {
  display: flex;
  text-align: center;
  margin-bottom: -0.8em;
  line-height: 1em;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-checkbox-label > .partner-benefits-checkbox-icon {
  margin: 0 0.4em 0 0.4em;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-checkbox-label > .partner-benefits-checkbox-icon.fa-award {
  margin: 0 0.6em 0 0.6em;
}

.NotesSection > .Section >.ui.grid > .row > .column > .partner-benefits-checkbox-label > .field > .partner-benefits-checkbox {
  margin-right: 0.3em;
}
