.TaskQueue > span {
  font-weight: 700;
}

.TaskQueue .ui.table {
  margin-top: .5em;
}

.TaskQueue .ui.table td {
  border: 0;
  font-weight: 400;
  font-size: 0.93em;
  padding: .3em 0 .3em 0;
}

.TaskQueue .ui.table td:nth-of-type(2) {
  text-align: right;
}
