.TasksNavigation > .menu {
  width: 100% !important;
}

.TasksNavigation > .menu > .item > .svg-inline--fa {
  margin-right: 0.5em;
}

.TasksNavigation > hr {
  border-color: rgba(34,36,38,.15);
  border-width: 0.5px;
  margin-left: -1rem;
  margin-right: -1rem;
}

.TasksNavigation > .menu > div > .item {
  margin-left: 0rem !important;
}

.TasksNavigation .hide {
  display: none;
}
