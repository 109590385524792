.BasicInformationSection .ui.grid .field .row.inheritance-label {
  color: #7F8FA4;
  font-size: .93em;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.BasicInformationSection .row .ui.basic.button {
  cursor: default;
  background: white !important;
}

.BasicInformationSection .row .ui.basic.button:hover {
  color: rgba(0,0,0,.6)!important;
  box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset !important;
}
