.ContactInformationSection .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled, .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
  opacity: 1.0;
}

.ContactInformationSection > .Section .no-physical-location-checkbox {
  display: flex !important;
}

.ContactInformationSection > .Section .no-physical-location-checkbox {
  color: #7F8FA4;
  font-size: 0.93em;
  font-weight: 700;
}

.ContactInformationSection > .Section .no-physical-location-checkbox > a {
  margin-top: 0.12em;
  margin-left: 0.25em;
}
